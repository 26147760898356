import {
  MARKETPLACE_LOGIN_UPDATE_ACCOUNT_PROPERTY,
  MARKETPLACE_LOGIN_UPDATE_ACCOUNTS,
  MARKETPLACE_LOGIN_UPDATE_FILTER,
  MARKETPLACE_LOGOUT,
} from '../actions';

const defaultState = {
  userToken: '',
  accountToken: '',
  isLoggingIn: false,
  isPasswordResetInProgress: false,
  loadingAccountId: '',
  isPendingAccounts: false,
  accounts: [],
  filteredAccounts: [],
  userName: '',
  filter: '',
  returnUrl: '',
};

function isMatching(str, subStr) {
  return str.toLowerCase().includes(subStr.toLowerCase());
}

export const accountReducer = (state = defaultState, action) => {
  switch (action.type) {
    case MARKETPLACE_LOGIN_UPDATE_ACCOUNT_PROPERTY:
      return { ...state, [action.prop]: action.value };
    case MARKETPLACE_LOGIN_UPDATE_ACCOUNTS:
      return {
        ...state,
        accounts: action.accounts,
        filteredAccounts: action.accounts.filter(
          account => isMatching(account.name, state.filter),
        ).slice(0, 6),
      };
    case MARKETPLACE_LOGIN_UPDATE_FILTER:
      return {
        ...state,
        filter: action.filter,
        filteredAccounts: state.accounts.filter(
          account => isMatching(account.name, action.filter),
        ).slice(0, 6),
      };
    case MARKETPLACE_LOGOUT:
      return defaultState;
    default:
      return state;
  }
};
