import {
  MARKETPLACE_NOTY_SHOW,
  MARKETPLACE_NOTY_DISMISS,
  MARKETPLACE_NOTY_DIMISS_ALL,
} from './actions';

export function marketPlaceNotyShow(notifObj) {
  return {
    type: MARKETPLACE_NOTY_SHOW,
    notifObj,
  };
}

export function marketPlaceNotyDismiss(_id) {
  return {
    type: MARKETPLACE_NOTY_DISMISS,
    _id,
  };
}

export function marketPlaceNotyDismissAll() {
  return {
    type: MARKETPLACE_NOTY_DIMISS_ALL,
  };
}

const _toNotifObject = (type, text, options) => ({
  type,
  text,
  ...options,
});

export function marketPlaceSuccessNotyShow(text, options) {
  return marketPlaceNotyShow(_toNotifObject('success', text, options));
}

export function marketPlaceErrorNotyShow(text, options) {
  return marketPlaceNotyShow(_toNotifObject('error', text, options));
}

export function marketPlaceWarningNotyShow(text, options) {
  return marketPlaceNotyShow(_toNotifObject('warning', text, options));
}

export function marketPlaceNotifNotyShow(text, options) {
  return marketPlaceNotyShow(_toNotifObject('notif', text, options));
}

export function marketPlaceInfoNotyShow(text, options) {
  return marketPlaceNotyShow(_toNotifObject('info', text, options));
}
