import { all } from 'redux-saga/effects';
import {
  watchUserLoginSaga,
  watchAccountsRequestSaga,
  watchRestoreTokenSaga,
  watchClearLSSaga,
  watchAccountLoginSaga,
  watchPasswordReset,
  watchPasswordRestore,
} from './loginSaga';
import { watchJoinUsSubmit } from './joinUsSaga';


export default function* sagas() {
  yield all([
    watchUserLoginSaga(),
    watchAccountsRequestSaga(),
    watchRestoreTokenSaga(),
    watchClearLSSaga(),
    watchAccountLoginSaga(),
    watchPasswordReset(),
    watchPasswordRestore(),
    watchJoinUsSubmit(),
  ]);
}
