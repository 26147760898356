import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { getToken, getIsLoggingIn } from '../common';

const LoginRoute = ({
  component: ComponentToLoad,
  logged,
  isLoggingIn,
  ...rest
}) => (
  <Route
    {...rest}
    render={() => {
      if (logged && !isLoggingIn) return <Redirect to="/accounts" />;
      return <ComponentToLoad {...rest} />;
    }}
  />
);


const mapStateToProps = state => ({
  logged: getToken(state).length > 0,
  isLoggingIn: getIsLoggingIn(state),
});


export const LoginRouteContainer = connect(
  mapStateToProps,
  null,
)(LoginRoute);
