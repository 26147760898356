import { MP_SET_UPDATE, MP_SET_CLEAR } from '../actions';

export const setsReducer = (state = {}, action) => {
  switch (action.type) {
    case MP_SET_UPDATE:
      return {
        ...state,
        [action.name]: action.items,
      };

    case MP_SET_CLEAR:
      return {
        ...state,
        [action.name]: [],
      };

    default:
      return state;
  }
};
