import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// notification item
export class NotificationItem extends Component {
  static propTypes = {
    onDismiss: PropTypes.func.isRequired,
    notifObj: PropTypes.shape().isRequired,
  };

  notifItemStyles = {
    success: {
      icon: 'mp-icon mp-icon-check',
      body: 'success',
    },
    error: {
      icon: 'mp-icon mp-icon-close-circle',
      body: 'error',
    },
    warning: {
      icon: 'mp-icon mp-icon-exclamation-triangle',
      body: 'warning',
    },
    notif: {
      icon: 'mp-icon mp-icon-bell',
      body: 'notif',
    },
    info: {
      icon: 'mp-icon mp-icon-info-circle',
      body: 'info',
    },
  };

  constructor(props) {
    super(props);

    this.timeoutEvent = undefined;
    this._handleOnClick = this._handleOnClick.bind(this);
    this._handleOnMouseEnter = this._handleOnMouseEnter.bind(this);
  }

  componentDidMount() {
    const { onDismiss, notifObj, defaultTimeout } = this.props;

    if (notifObj && notifObj.timeout !== undefined && notifObj.timeout) {
      let timeout = defaultTimeout;

      if (notifObj.timeout.toString() !== 'true') {
        timeout = notifObj.timeout;
      }

      this.timeoutEvent = setTimeout(() => {
        if (onDismiss) onDismiss(notifObj._id);
      }, timeout);
    }
  }

  componentWillUnmount() {
    if (this.timeoutEvent !== undefined && this.timeoutEvent) {
      clearTimeout(this.timeoutEvent);
    }
  }

  getIconClassName(type) {
    return this.notifItemStyles[type].icon;
  }

  getClassName(type) {
    return classNames('notification-item', `notification-item--${this.notifItemStyles[type].body}`);
  }

  _handleOnClick(event) {
    event.preventDefault();

    const { onDismiss, notifObj } = this.props;

    if (!notifObj.closeWith || (notifObj.closeWith !== undefined && notifObj.closeWith === 'click')) {
      if (onDismiss) onDismiss(notifObj._id);
    }
  }

  _handleOnMouseEnter(event) {
    event.preventDefault();

    const { onDismiss, notifObj } = this.props;

    if (notifObj.closeWith !== undefined && notifObj.closeWith === 'hover') {
      if (onDismiss) onDismiss(notifObj._id);
    }
  }

  render() {
    const { notifObj } = this.props;
    const { type = 'success', text } = notifObj;

    return (
      <li className={this.getClassName(type)}>
        <div
          className="notification-item_container"
          onClick={this._handleOnClick}
          onMouseEnter={this._handleOnMouseEnter}
        >
          <div className="notification-item_icon">
            <i className={this.getIconClassName(type)} aria-hidden="true" />
          </div>
          <div className="notification-item_text" dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      </li>
    );
  }
}
