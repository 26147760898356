import { LSKeys, permanentKeys } from '.';

export const LSHelper = {
  get(key) {
    let value;

    try {
      value = JSON.parse(localStorage.getItem(key));
    } catch (e) {
      if (localStorage[key]) {
        value = { data: localStorage.getItem(key) };
      }
    }

    if (value && typeof value === 'object' && typeof value.data !== 'undefined') {
      return value.data;
    }

    return undefined;
  },
  set(key, value) {
    try {
      localStorage.setItem(key, JSON.stringify({ data: value }));
    } catch (e) {
      if (console) console.warn(`didn't save the "${key}": "${value}" pair, because the localStorage is full.`);
    }
  },
  remove(key) {
    localStorage.removeItem(key);
  },
  removeMPRecords() {
    Object.keys(LSKeys).forEach(k => !permanentKeys.includes(k) && LSHelper.remove(LSKeys[k]));
  },
  hasKey(key) {
    return Object.prototype.hasOwnProperty.call(localStorage, key);
  },
};
