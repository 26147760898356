import { connect } from 'react-redux';
import { parse } from 'query-string';
import { LoginForm } from '../components';
import { marketplaceLoginUserSubmit } from '../actions';
import { getIsLoggingIn } from '../common';

const mapStateToProps = state => ({
  isLoggingIn: getIsLoggingIn(state),
});

const mapDispatchToProps = (dispatch, { location }) => {
  const { redirectUrl } = parse(location.search);

  return {
    onSubmit: (userName, password, recaptchaToken) => {
      dispatch(
        marketplaceLoginUserSubmit({
          userName,
          password,
          recaptchaToken,
          shouldUseRecaptcha: true,
          redirectUrl,
        }),
      );
    },
    onSubmitAlt: (userName, password) => {
      dispatch(marketplaceLoginUserSubmit({ userName, password, shouldUseRecaptcha: false, redirectUrl }));
    },
  };
};

export const LoginFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginForm);
