import { put, takeLatest, call, delay } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { LSHelper, LSKeys } from '@mysalegroup/marketplace-store-management';
import { marketPlaceErrorNotyShow, marketPlaceSuccessNotyShow } from '@mysalegroup/marketplace-noty';

import {
  MARKETPLACE_LOGIN_USER_SUBMIT,
  MARKETPLACE_LOGIN_ACCOUNT_SUBMIT,
  MARKETPLACE_ACCOUNTS_REQUEST,
  MARKETPLACE_LOGIN_RESTORE_TOKEN,
  MARKETPLACE_LOGOUT,
  MARKETPLACE_LOGIN_RESET_PASSWORD,
  MARKETPLACE_LOGIN_RESTORE_PASSWORD,
  marketplaceLoginUpdateAccountProperty,
  marketplaceLoginUpdateAccounts,
  marketplaceLoginAccountSubmit,
} from '../actions';
import { userLogin, getUser, accountLogin, resetPassword, restorePassword } from './api';
import { getRedirectURL, allowedGlobalView, AccProps } from '../common';
import { Logger } from '../containers';

const RedirectToPasswordResetDelaySec = 10;
const ResetPasswordErrorCode = {
  userCredentialsNotExist: 1,
  userIsLocked: 2,
  passwordNotStrong: 3,
  invalidResetToken: 4,
  resetTokenHasExpired: 5,
};
const ResetPasswordErrorMessages = {
  [ResetPasswordErrorCode.userCredentialsNotExist]: {
    message: 'User with the specified name does not have credentials.</br>Please contact Marketplace support',
    useRedirect: false,
  },
  [ResetPasswordErrorCode.userIsLocked]: {
    message: 'User is locked.</br>Please contact Marketplace support',
    useRedirect: false,
  },
  [ResetPasswordErrorCode.passwordNotStrong]: {
    message:
      'Sorry, your password is too weak.<br/>It must be at least 8 symbols long and contain digits and letters.<br/>Please try again',
    useRedirect: false,
  },
  [ResetPasswordErrorCode.invalidResetToken]: {
    message: `Invalid reset password link.<br/>Please contact Marketplace support or<br/>issue new reset password link using restore password page.<br/>You will be automatically redirected in ${RedirectToPasswordResetDelaySec} seconds`,
    useRedirect: true,
  },
  [ResetPasswordErrorCode.resetTokenHasExpired]: {
    message: `Password reset link is expired.<br/>Please issue new reset password link using restore password page.<br/>You will be automatically redirected in ${RedirectToPasswordResetDelaySec} seconds`,
    useRedirect: true,
  },
};

function* handleGetUserFailed() {
  const userDataFromLS = yield call(LSHelper.get, LSKeys.userData);
  if (userDataFromLS) {
    yield put(marketplaceLoginUpdateAccountProperty(AccProps.userName, userDataFromLS.userName));
    yield put(marketplaceLoginUpdateAccountProperty(AccProps.roles, userDataFromLS.roles));
  }
  const accountId = yield call(LSHelper.get, LSKeys.accountId);
  if (accountId) {
    yield put(marketplaceLoginUpdateAccountProperty(AccProps.returnUrl, getRedirectURL()));
  }
}

function* handleUserDataRequest() {
  const apiName = 'getUser';

  let userData = null;

  yield call(getUser, {
    *onSuccess(response) {
      userData = yield response.json();

      if (!userData) {
        return;
      }

      const { accounts, menu, roles, permissions, userName } = userData;

      yield call(LSHelper.set, LSKeys.userData, {
        userName,
        permissions,
        roles,
        lastUpdated: Date.now(),
      });

      yield call(
        LSHelper.set,
        LSKeys.accountList,
        accounts.reduce((result, { id, name, data }) => {
          result[id] = {
            id,
            name,
            saleType: (data && data.sale_type) || 'sale',
            accountType: (data && data.account_type) || '',
            pricingType: (data && data.pricing_type) || 'manual',
          };
          return result;
        }, {}),
      );

      yield call(LSHelper.set, LSKeys.userMenu, menu);
    },
    *onBadResponse(response) {
      if (response.status === 401) {
        yield put(push('/logout'));
      } else {
        const errorMessage = `${apiName} - Bad response: ${response.status} ${response.statusText}`;
        console.warn(errorMessage);
        Logger.newRelicLog(errorMessage);
      }
    },
    onError(e) {
      console.error(apiName, e);
    },
  });

  return userData;
}

function* handleUserLogin({ userName, password, recaptchaToken, shouldUseRecaptcha, redirectUrl }) {
  yield put(marketplaceLoginUpdateAccountProperty(AccProps.isLoggingIn, true));
  const apiName = 'userLogin';

  let userToken = null;
  let errorMessage = null;

  yield call(userLogin, {
    shouldUseRecaptcha,
    payload: { userName, password, recaptchaToken },
    *onSuccess(response) {
      userToken = yield response.json();
    },
    onBadResponse(response) {
      if (response.status === 401) {
        errorMessage = 'Incorrect user name or password';
      } else {
        const error = `${apiName} - Bad response: ${response.status} ${response.statusText}`;
        console.warn(error);
        Logger.newRelicLog(error);
      }
    },
    onError(e) {
      console.error(apiName, e);
    },
  });

  if (userToken) {
    yield call(LSHelper.set, LSKeys.userToken, userToken);
    yield put(marketplaceLoginUpdateAccountProperty(AccProps.userToken, userToken));

    if (redirectUrl) {
      const userData = yield call(handleUserDataRequest);

      if (userData) {
        window.location.href = redirectUrl;
        return;
      }
    }

    yield put(push('/accounts'));
  } else {
    yield put(
      marketPlaceErrorNotyShow(errorMessage || 'Oops! Something went wrong.', {
        timeout: 20000,
        closeWith: 'click',
      }),
    );
  }

  yield put(marketplaceLoginUpdateAccountProperty(AccProps.isLoggingIn, false));
}

function* handleAccountLogin(action) {
  const { id } = action;
  yield put(marketplaceLoginUpdateAccountProperty(AccProps.isLoggingIn, true));
  yield put(marketplaceLoginUpdateAccountProperty(AccProps.loadingAccountId, id));
  const accountToken = yield call(accountLogin, id);
  if (accountToken) {
    let accountsTokens = yield call(LSHelper.get, LSKeys.accountsTokens);

    if (accountsTokens) {
      accountsTokens[id] = accountToken;
    } else {
      accountsTokens = { [id]: accountToken };
    }
    yield call(LSHelper.set, LSKeys.accountTokens, accountsTokens);
    yield call(LSHelper.set, LSKeys.accountId, id);
    document.location.href = getRedirectURL();
  }
  yield put(marketplaceLoginUpdateAccountProperty(AccProps.loadingAccountId, ''));
  yield put(marketplaceLoginUpdateAccountProperty(AccProps.isLoggingIn, false));
}

function* handleAccountsRequest() {
  yield put(marketplaceLoginUpdateAccountProperty(AccProps.isPendingAccounts, true));

  const userData = yield call(handleUserDataRequest);

  if (userData) {
    const { accounts, permissions } = userData;

    // Do fast login if possible
    if (accounts.length === 1 && !allowedGlobalView.some(permission => permissions.indexOf(permission) > -1)) {
      yield put(marketplaceLoginAccountSubmit(accounts[0].id));
      return;
    }

    yield put(marketplaceLoginUpdateAccounts(userData.accounts));
    yield put(marketplaceLoginUpdateAccountProperty(AccProps.userName, userData.userName));
    yield put(marketplaceLoginUpdateAccountProperty(AccProps.roles, userData.roles));
    yield put(marketplaceLoginUpdateAccountProperty(AccProps.permissions, userData.permissions));
  } else {
    yield* handleGetUserFailed();
  }

  yield put(marketplaceLoginUpdateAccountProperty(AccProps.isPendingAccounts, false));
}

function* handlePasswordReset(action) {
  yield put(marketplaceLoginUpdateAccountProperty(AccProps.isPasswordResetInProgress, true));
  const { payload } = action;
  if (payload.resetToken) {
    yield call(resetPassword, {
      payload,
      *onSuccess() {
        yield put(push('/'));
      },
      *onBadResponse(response) {
        const respBody = yield response.json();
        const error = ResetPasswordErrorMessages[respBody.errorCode];

        yield put(
          marketPlaceErrorNotyShow(error.message, {
            timeout: 20000,
            closeWith: 'click',
          }),
        );
        if (error.useRedirect) {
          yield delay(RedirectToPasswordResetDelaySec * 1000);
          yield put(push('/restore-password'));
        }
      },
      *onError() {
        yield put(
          marketPlaceErrorNotyShow('Something went wrong. New password was not set.', {
            timeout: 20000,
            closeWith: 'click',
          }),
        );
      },
    });
  } else {
    yield put(
      marketPlaceErrorNotyShow('Password reset token is missing', {
        timeout: 20000,
        closeWith: 'click',
      }),
    );
  }

  yield put(marketplaceLoginUpdateAccountProperty(AccProps.isPasswordResetInProgress, false));
}

function* handlePasswordRestore(action) {
  yield put(marketplaceLoginUpdateAccountProperty(AccProps.isPasswordRestoreInProgress, true));

  const { email, recaptchaToken = 'none', shouldUseRecaptcha } = action.payload;

  if (email) {
    yield call(restorePassword, {
      shouldUseRecaptcha,
      payload: { email, recaptchaToken },
      *onSuccess() {
        yield put(
          marketPlaceSuccessNotyShow('<b>Reset link is sent. Please, check your email.</b>', {
            timeout: 20000,
            closeWith: 'click',
          }),
        );
      },
      *onBadResponse() {
        yield put(
          marketPlaceErrorNotyShow('<b>Something went wrong. Please, try again later.</b>', {
            timeout: 20000,
            closeWith: 'click',
          }),
        );
      },
      *onError() {
        yield put(
          marketPlaceErrorNotyShow('<b>Something went wrong. Please, try again later.</b>', {
            timeout: 20000,
            closeWith: 'click',
          }),
        );
      },
    });
  }

  yield put(marketplaceLoginUpdateAccountProperty(AccProps.isPasswordRestoreInProgress, false));
}

function* handleTokenRestore() {
  const token = yield call(LSHelper.get, LSKeys.userToken);
  yield put(marketplaceLoginUpdateAccountProperty(AccProps.userToken, token));
}

function* handleLogout() {
  yield call(LSHelper.removeMPRecords);
}

export function* watchUserLoginSaga() {
  yield takeLatest(MARKETPLACE_LOGIN_USER_SUBMIT, handleUserLogin);
}

export function* watchAccountLoginSaga() {
  yield takeLatest(MARKETPLACE_LOGIN_ACCOUNT_SUBMIT, handleAccountLogin);
}

export function* watchAccountsRequestSaga() {
  yield takeLatest(MARKETPLACE_ACCOUNTS_REQUEST, handleAccountsRequest);
}

export function* watchRestoreTokenSaga() {
  yield takeLatest(MARKETPLACE_LOGOUT, handleLogout);
}

export function* watchClearLSSaga() {
  yield takeLatest(MARKETPLACE_LOGIN_RESTORE_TOKEN, handleTokenRestore);
}

export function* watchPasswordReset() {
  yield takeLatest(MARKETPLACE_LOGIN_RESET_PASSWORD, handlePasswordReset);
}

export function* watchPasswordRestore() {
  yield takeLatest(MARKETPLACE_LOGIN_RESTORE_PASSWORD, handlePasswordRestore);
}
