import React, { Component } from 'react';
import { InputComponent, ButtonComponent } from './form';


export class PasswordResetForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newPassword: '',
      newPasswordRepeat: '',
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(value, field) {
    this.setState({ [field]: value });
  }

  onSubmit() {
    const { onSubmit } = this.props;
    const { newPassword } = this.state;

    onSubmit(newPassword);
  }

  render() {
    const { newPassword, newPasswordRepeat } = this.state;
    const { isPasswordResetInProgress } = this.props;
    const resetButtonDisabled = isPasswordResetInProgress
      || newPassword.length === 0
      || newPassword !== newPasswordRepeat;
    const buttonText = isPasswordResetInProgress ? 'Reset In Progress...' : 'Reset Password';

    return (
      <form className="login-form" autoComplete="off">
        <div className="login-form_field">
          <label className="login-form_input-label" htmlFor="newPassword">Password</label>
          <InputComponent
            id="newPassword"
            name="newPassword"
            type="password"
            value={newPassword}
            onChange={this.onChange}
            className="login-form_input-text"
          />
        </div>
        <div className="login-form_field">
          <label className="login-form_input-label" htmlFor="newPasswordRepeat">Repeat Password</label>
          <InputComponent
            id="newPasswordRepeat"
            name="newPasswordRepeat"
            type="password"
            value={newPasswordRepeat}
            onChange={this.onChange}
            className="login-form_input-text"
          />
        </div>
        <div className="login-form_field">
          <ButtonComponent onClick={this.onSubmit} disabled={resetButtonDisabled}>
            {buttonText}
          </ButtonComponent>
        </div>
      </form>
    );
  }
}
