import { connect } from 'react-redux';
import queryString from 'query-string';
import { PasswordResetForm } from '../components';
import { marketplaceLoginResetPassword } from '../actions';
import { getIsPasswordResetInProgress } from '../common';

const mapStateToProps = state => ({
  isPasswordResetInProgress: getIsPasswordResetInProgress(state),
});

const mapDispatchToProps = (dispatch) => {
  // This logic is needed to process token from systemAPI which contains +, =, /.
  // If there will be fix, then get token from router (/reset-password/:resetToken)
  let { token } = queryString.parse(queryString.extract(window.location.href));
  if (token) token = token.replace(/ /g, '+');

  return {
    onSubmit: (newPassword) => {
      dispatch(marketplaceLoginResetPassword({ resetToken: token, newPassword }));
    },
  };
};


export const PasswordResetFormContainer = connect(
  mapStateToProps, mapDispatchToProps,
)(PasswordResetForm);
