import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { marketplaceAccountsRequest } from '../actions';
import { getToken } from '../common';

class AccountsRoute extends Component {
  componentWillMount() {
    const { logged, requestAccounts } = this.props;

    if (logged) {
      requestAccounts();
    }
  }

  render() {
    const { component: ComponentToLoad, pending, logged, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={() => {
          if (!logged) return <Redirect to="/" />;
          if (pending) {
            return <div className="account-item-caption caption-above">Loading...</div>;
          }

          return <ComponentToLoad {...this.props} />;
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  pending: state.account.isPendingAccounts,
  logged: getToken(state).length > 0,
});

const mapDispatchToProps = dispatch => ({
  requestAccounts: () => {
    dispatch(marketplaceAccountsRequest());
  },
});

export const AccountsRouteContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountsRoute);
