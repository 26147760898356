import {
  MARKETPLACE_NOTY_SHOW,
  MARKETPLACE_NOTY_DISMISS,
  MARKETPLACE_NOTY_DIMISS_ALL,
} from './actions';

export const guid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return (`${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`);
};

const _defaultState = {
  options: {
    maxVisible: 5,
    dismissQueue: true,
    timeout: 5000,
  },
  notifications: [],
};

const _pushToArr = (state, item) => {
  const stateCopy = Object.assign({}, state);
  let stateArray;

  item._id = guid();

  if (!stateCopy.notifications) {
    stateCopy.notifications = [item];
  } else {
    const newArr = stateCopy.notifications.slice();

    if (!state.options.dismissQueue) {
      stateCopy.notifications = [item];
    } else {
      // check (force prop) if we should insert the item at the beginning of the array
      if (item.force !== undefined && item.force) {
        stateCopy.notifications = [item].concat(newArr).slice();
      } else {
        newArr.push(item);
        stateCopy.notifications = newArr;
      }
    }
  }

  return stateCopy;
};

const _removeFromArr = (state, idToDel) => {
  const stateCopy = Object.assign({}, state);
  let newArr = [];

  if (!stateCopy.notifications) {
    return stateCopy;
  }
  newArr = stateCopy.notifications.filter(el => el._id !== idToDel);

  stateCopy.notifications = newArr;


  return stateCopy;
};

export const notyReducer = (state = _defaultState, action) => {
  let stateCopy = {};
  switch (action.type) {
    case MARKETPLACE_NOTY_SHOW:
      stateCopy = _pushToArr(state, action.notifObj);
      return stateCopy;
    case MARKETPLACE_NOTY_DISMISS:
      stateCopy = _removeFromArr(state, action._id);
      return stateCopy;
    case MARKETPLACE_NOTY_DIMISS_ALL:
      stateCopy = Object.assign({}, state);
      stateCopy.notifications = [];
      return stateCopy;
    default:
      return state;
  }
};
