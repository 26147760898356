export const MARKETPLACE_LOGIN_USER_SUBMIT = 'MARKETPLACE_LOGIN_USER_SUBMIT';
export const MARKETPLACE_LOGIN_ACCOUNT_SUBMIT = 'MARKETPLACE_LOGIN_ACCOUNT_SUBMIT';
export const MARKETPLACE_ACCOUNTS_REQUEST = 'MARKETPLACE_ACCOUNTS_REQUEST';
export const MARKETPLACE_LOGIN_UPDATE_ACCOUNT_PROPERTY = 'MARKETPLACE_LOGIN_UPDATE_ACCOUNT_PROPERTY';
export const MARKETPLACE_LOGIN_UPDATE_ACCOUNTS = 'MARKETPLACE_LOGIN_UPDATE_ACCOUNTS';
export const MARKETPLACE_LOGIN_UPDATE_FILTER = 'MARKETPLACE_LOGIN_UPDATE_FILTER';
export const MARKETPLACE_LOGIN_RESTORE_TOKEN = 'MARKETPLACE_LOGIN_RESTORE_TOKEN';
export const MARKETPLACE_LOGOUT = 'MARKETPLACE_LOGOUT';
export const MARKETPLACE_LOGIN_RESET_PASSWORD = 'MARKETPLACE_LOGIN_RESET_PASSWORD';
export const MARKETPLACE_LOGIN_RESTORE_PASSWORD = 'MARKETPLACE_LOGIN_RESTORE_PASSWORD';

export function marketplaceLoginUserSubmit({
  userName,
  password,
  recaptchaToken = 'none',
  shouldUseRecaptcha,
  redirectUrl,
}) {
  return {
    type: MARKETPLACE_LOGIN_USER_SUBMIT,
    userName,
    password,
    recaptchaToken,
    shouldUseRecaptcha,
    redirectUrl,
  };
}

export function marketplaceLoginUpdateAccountProperty(prop, value) {
  return {
    type: MARKETPLACE_LOGIN_UPDATE_ACCOUNT_PROPERTY,
    prop,
    value,
  };
}

export function marketplaceLoginUpdateAccounts(accounts = []) {
  return {
    type: MARKETPLACE_LOGIN_UPDATE_ACCOUNTS,
    accounts,
  };
}

export function marketplaceLoginUpdateAFilter(filter) {
  return {
    type: MARKETPLACE_LOGIN_UPDATE_FILTER,
    filter,
  };
}

export function marketplaceAccountsRequest() {
  return {
    type: MARKETPLACE_ACCOUNTS_REQUEST,
  };
}

export function marketplaceLoginRestoreToken() {
  return {
    type: MARKETPLACE_LOGIN_RESTORE_TOKEN,
  };
}

export function marketplaceLogout() {
  return {
    type: MARKETPLACE_LOGOUT,
  };
}

export function marketplaceLoginAccountSubmit(id) {
  return {
    type: MARKETPLACE_LOGIN_ACCOUNT_SUBMIT,
    id,
  };
}

export function marketplaceLoginResetPassword(payload) {
  return {
    type: MARKETPLACE_LOGIN_RESET_PASSWORD,
    payload,
  };
}

export function marketplaceLoginRestorePassword(payload) {
  return {
    type: MARKETPLACE_LOGIN_RESTORE_PASSWORD,
    payload,
  };
}
