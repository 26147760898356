import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { SearchInputContainer } from '../containers';

export class AccountsList extends React.Component {
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (e) => {
    const { accounts, onAccountClick } = this.props;
    const keyName = e.key;
    if (keyName === 'Enter' && accounts.length === 1) {
      onAccountClick(accounts[0].id);
    }
  };

  render() {
    const {
      accounts,
      onAccountClick,
      userName,
      displaySearch,
      loadingAccountId,
      isLoggingIn,
      displayAccountsList,
      returnUrl,
      isPlanner,
    } = this.props;

    return (
      <Fragment>
        {displaySearch && (<SearchInputContainer />)}

        <Link
          className="account-item-caption caption-above"
          to="/logout"
          title="Click to sign out"
        >
          {userName}
        </Link>

        <div className="account-item-caption caption-below">
          {displayAccountsList
            ? 'Select supplier account you want to manage'
            : 'List of accounts isn`t available at the moment. Please retry later.'
          }
        </div>

        {isPlanner && (
          <a className="account-item account-item_global" href="/sales/#/?viewTab=2">
            <div className="account-item_name">
              <span className="account-item_color-first">MY</span>
              <span className="account-item_color-second">SALE</span>
              {'Global'}
            </div>
            <div className="account-item_arrow" />
          </a>
        )}

        {displayAccountsList && (
          <div className="account-items" id="mp-buttons-accounts">
            {accounts.map((account, idx) => {
              const matchId = loadingAccountId === account.id;

              return (
                <button
                  className={classNames('account-item', {
                    'account-item__processing': isLoggingIn && matchId,
                    'account-item__disabled': isLoggingIn && !matchId,
                  })}
                  key={account.id}
                  onClick={() => onAccountClick(account.id)}
                  tabIndex={idx}
                  type="button"
                >
                  <span className="account-item_name">
                    <span>{account.name}</span>
                  </span>
                  <span className="account-item_arrow" />
                </button>
              );
            })}
          </div>
        )}
        {!displayAccountsList && returnUrl && (
          <a
            className="account-item-caption caption-above"
            href={returnUrl}
          >
            {'Return to site'}
          </a>
        )}
      </Fragment>
    );
  }
}
