import { AccProps } from '.';

export function getToken(state) {
  return state.account.accountToken || state.account.userToken || '';
}

export function getIsLoggingIn(state) {
  return state.account[AccProps.isLoggingIn];
}

export function getIsPasswordResetInProgress(state) {
  return state.account[AccProps.isPasswordResetInProgress];
}

export function getIsPasswordRestoreInProgress(state) {
  return state.account[AccProps.isPasswordRestoreInProgress];
}

export function getIsJoinUsInProgress(state) {
  return state.account[AccProps.isJoinUsInProgress];
}
