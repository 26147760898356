import React from 'react';

export const ButtonComponent = ({
  onClick,
  children,
  disabled,
  id,
}) => (
  <button
    type="submit"
    id={id}
    className="login-form_input-button"
    onClick={(e) => {
      e.preventDefault();
      onClick();
    }}
    disabled={disabled}
  >
    {children}
  </button>
);
