import { LSHelper, LSKeys } from '@mysalegroup/marketplace-store-management';


function getDefaultPageFromMenu() {
  const menu = LSHelper.get(LSKeys.userMenu);
  if (!menu) {
    return '';
  }

  let [defaultMenuItem = {}] = menu;
  for (let i = 1; i < menu.length; i += 1) {
    if (menu[i].isStartPage) {
      defaultMenuItem = menu[i];
      break;
    }
  }

  if (!defaultMenuItem || !defaultMenuItem.url) {
    return '';
  }

  const page = defaultMenuItem.url.replace(/^\/|\/$/g, '');

  if (!defaultMenuItem.hasAccount) {
    return page;
  }

  const accountId = LSHelper.get(LSKeys.accountId);
  return `accounts/${accountId}/${page}`;
}

export const getRedirectURL = (page = getDefaultPageFromMenu()) => `${window.location.protocol}//${window.location.host}/${page}/`;
