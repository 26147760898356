export class Logger {
  static newRelicLog(e, attributes) {
    if (!window.NREUM || typeof window.NREUM.noticeError !== 'function') {
      return;
    }

    const error = (!(e instanceof Error)) ? new Error(e) : e;
    window.NREUM.noticeError(error, attributes);
  }

  static newRelicPageAction(actionName, attributes) {
    if (!window.NREUM || typeof window.NREUM.addPageAction !== 'function'
      || !actionName) {
      return;
    }

    window.NREUM.addPageAction(actionName, attributes);
  }
}
