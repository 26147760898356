import { connect } from 'react-redux';
import { SearchInput } from '../components';
import { marketplaceLoginUpdateAFilter } from '../actions';


const mapStateToProps = (state) => {
  const { filter } = state.account;

  return ({
    filter,
  });
};

const mapDispatchToProps = dispatch => ({
  onChange: (value) => { dispatch(marketplaceLoginUpdateAFilter(value)); },
});


export const SearchInputContainer = connect(mapStateToProps, mapDispatchToProps)(SearchInput);
