import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import Recaptcha from 'reaptcha';

import { RECAPTCHA_SITE_KEY } from '../common';
import { Logger } from '../containers/newrelic_logger';


export class PasswordRestoreForm extends Component {
  shouldUseRecaptcha = RECAPTCHA_SITE_KEY.length > 0;

  isCaptchaReady = false;

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      isEmailValid: false,
    };
    this.inputRef = createRef();
    this.recaptchaRef = createRef();
  }

  componentDidMount() {
    this.inputRef.current.focus();
  }

  onChange = (e) => {
    const { value, validity } = e.target;

    this.setState({
      email: value,
      isEmailValid: !validity.typeMismatch && value.length > 0,
    });
  };

  recaptchaCb = (recaptchaToken) => {
    if (recaptchaToken == null) return;

    this.onSubmit(recaptchaToken);

    this.recaptchaRef.current.reset();
  };

  onSubmit = (recaptchaToken) => {
    const { onSubmit } = this.props;
    const { email, isEmailValid } = this.state;

    if (!isEmailValid) return;

    onSubmit(email, recaptchaToken);
  };

  onSubmitAlt = () => {
    const { onSubmitAlt } = this.props;
    const { email, isEmailValid } = this.state;

    if (!isEmailValid) return;

    onSubmitAlt(email);
  };

  _onSubmit = (e) => {
    e.preventDefault();

    if (this.shouldUseRecaptcha && this.isCaptchaReady) {
      this.executeCaptcha();
      return;
    }

    this.onSubmitAlt();
  };

  executeCaptcha = () => {
    const { isEmailValid } = this.state;

    if (!isEmailValid) return;

    this.recaptchaRef.current.execute();
  };

  logCaptchaError = () => {
    Logger.newRelicLog(new Error('Recaptcha error'), { source: 'password restore form' });
  };

  setCaptchaReady = () => {
    this.isCaptchaReady = true;
  };

  render() {
    const { email, isEmailValid } = this.state;
    const { isPasswordRestoreInProgress } = this.props;
    const buttonText = isPasswordRestoreInProgress ? 'Restore In Progress...' : 'Restore Password';
    const isButtonDisabled = isPasswordRestoreInProgress || !isEmailValid;

    return (
      <form className="login-form" autoComplete="off" onSubmit={this._onSubmit}>
        {this.shouldUseRecaptcha && (
          <Recaptcha
            ref={this.recaptchaRef}
            sitekey={RECAPTCHA_SITE_KEY}
            size="invisible"
            onVerify={this.recaptchaCb}
            onError={this.logCaptchaError}
            onRender={this.setCaptchaReady}
            theme="dark"
          />
        )}
        <div className="login-form_field">
          <label className="login-form_input-label" htmlFor="email">Email</label>
          <input
            className="login-form_input-text"
            onChange={this.onChange}
            value={email}
            disabled={isPasswordRestoreInProgress}
            id="email"
            type="email"
            ref={this.inputRef}
            autoComplete="off"
          />
        </div>
        <div className="login-form_field">
          <input
            type="submit"
            value={buttonText}
            id="mp-button-submit"
            className="login-form_input-button"
            disabled={isButtonDisabled}
          />
        </div>
        <div className="login-form_field">
          <Link
            className="account-item-caption caption-above"
            to="/"
          >
            {'Back To Login'}
          </Link>
        </div>
      </form>
    );
  }
}
