import React, { Component, createRef } from 'react';
import classNames from 'classnames';

export class InputComponent extends Component {
  constructor(props) {
    super(props);
    this.inputRef = createRef();
  }

  componentDidMount() {
    const { autoFocus } = this.props;

    if (autoFocus) this.inputRef.current.focus();
  }

  render() {
    const {
      value,
      name,
      placeholder,
      disabled,
      onChange,
      type = 'text',
      className = '',
      id,
    } = this.props;
    const classNameCombined = classNames(
      className,
      { input__disabled: disabled },
    );
    return (
      <input
        className={classNameCombined}
        name={name}
        placeholder={placeholder}
        onChange={e => onChange(e.target.value, name)}
        value={value}
        disabled={disabled ? 'disabled' : undefined}
        type={type}
        ref={this.inputRef}
        id={id}
        autoComplete="off"
      />
    );
  }
}
