import React from 'react';
import { InputComponent } from './form';

export const SearchInput = ({
  filter,
  onChange,
}) => (
  <div className="account-search">
    <i className="account-search_icon" />
    <InputComponent
      autoFocus
      name="search"
      type="text"
      placeholder="Search"
      value={filter}
      onChange={onChange}
      className="account-search_input"
    />
  </div>
);
