import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { marketplaceLogout } from '../actions';

class LogoutRoute extends Component {
  componentWillMount() {
    const { logout } = this.props;

    logout();
  }

  render() {
    const { location } = this.props;
    return <Route render={() => <Redirect to={`/${location.search}`} />} />;
  }
}

const mapDispatchToProps = dispatch => ({
  logout: () => {
    dispatch(marketplaceLogout());
  },
});

export const LogoutRouteContainer = connect(
  null,
  mapDispatchToProps,
)(LogoutRoute);
