import { connect } from 'react-redux';
import { marketPlaceWarningNotyShow } from '@mysalegroup/marketplace-noty';

import { marketplaceLoginJoinUsSubmit } from '../actions';
import { JoinUsForm } from '../components';
import { getIsJoinUsInProgress } from '../common';

const mapStateToProps = state => ({
  isJoinUsInProgress: getIsJoinUsInProgress(state),
});

const mapDispatchToProps = dispatch => ({
  onSubmit(form, recaptchaToken = 'none') {
    dispatch(marketplaceLoginJoinUsSubmit({ ...form, recaptchaToken, shouldUseRecaptcha: true }));
  },
  onSubmitAlt(form) {
    dispatch(marketplaceLoginJoinUsSubmit({ ...form, shouldUseRecaptcha: false }));
  },
  showWarning(field) {
    dispatch(marketPlaceWarningNotyShow(
      `Please, complete ${field} field`,
      {
        timeout: 5000,
        closeWith: 'click',
      },
    ));
  },
});


export const JoinUsFormContainer = connect(mapStateToProps, mapDispatchToProps)(JoinUsForm);
