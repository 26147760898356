import { connect } from 'react-redux';

import { AccountsList } from '../components';
import { marketplaceLoginAccountSubmit } from '../actions';
import { allowedGlobalView } from '../common';

const mapStateToProps = (state) => {
  const {
    userName,
    accounts,
    filteredAccounts,
    loadingAccountId,
    isLoggingIn,
    roles = [],
    permissions = [],
    returnUrl,
  } = state.account;

  const _permissions = [...permissions, ...roles];

  return ({
    displaySearch: accounts.length > 6,
    displayAccountsList: accounts.length > 0,
    accounts: filteredAccounts,
    userName: userName !== '' ? userName : 'Log out',
    loadingAccountId,
    isPlanner: allowedGlobalView.some(permission => _permissions.indexOf(permission) > -1),
    isLoggingIn,
    returnUrl,
  });
};
const mapDispatchToProps = dispatch => ({
  onAccountClick: (id) => { dispatch(marketplaceLoginAccountSubmit(id)); },
});

export const AccountsListContainer = connect(mapStateToProps, mapDispatchToProps)(AccountsList);
