import { connect } from 'react-redux';
import { PasswordRestoreForm } from '../components';
import { marketplaceLoginRestorePassword } from '../actions';
import { getIsPasswordRestoreInProgress } from '../common';

const mapStateToProps = state => ({
  isPasswordRestoreInProgress: getIsPasswordRestoreInProgress(state),
});

const mapDispatchToProps = dispatch => ({
  onSubmit: (email, recaptchaToken) => {
    dispatch(marketplaceLoginRestorePassword({ email, recaptchaToken, shouldUseRecaptcha: true }));
  },
  onSubmitAlt: (email) => {
    dispatch(marketplaceLoginRestorePassword({ email, shouldUseRecaptcha: false }));
  },
});


export const PasswordRestoreFormContainer = connect(
  mapStateToProps, mapDispatchToProps,
)(PasswordRestoreForm);
