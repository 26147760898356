import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NotificationItem } from './NotificationItem';
import './styles.scss';

// Notification component

class NotificationComponent extends Component {
  static propTypes = {
    notifications: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  };

  static defaultProps = {
    ...Component.defaultProps,
    notifications: [],
    dismissQueue: true,
    maxVisible: 5,
  };

  constructor(props) {
    super(props);

    this._getNotificationList = this._getNotificationList.bind(this);
    this._handleOnNotyRemove = this._handleOnNotyRemove.bind(this);
    this._getNotificationData = this._getNotificationData.bind(this);
  }

  _handleOnNotyRemove(notifObj) {
    const { onNotyDismiss } = this.props;

    if (onNotyDismiss) onNotyDismiss(notifObj);
  }

  _getNotificationData() {
    const { dismissQueue, maxVisible, notifications } = this.props;
    // just return notifications array, this just contains one element if dismissQueue = false
    if (!dismissQueue) return notifications;

    const retNotifs = [];
    const max = (notifications.length < maxVisible) ? notifications.length : maxVisible;

    if (notifications.length > 0) {
      for (let i = 0; i < max; i += 1) {
        retNotifs.push(notifications[i]);
      }
    }
    return retNotifs;
  }

  _getNotificationList() {
    const notifications = this._getNotificationData();
    const { timeout } = this.props;

    const notifItems = notifications.map(noty => (
      <NotificationItem
        key={noty._id}
        notifObj={noty}
        onDismiss={this._handleOnNotyRemove}
        defaultTimeout={timeout}
      />
    ));

    return (
      <ul className="notification-list">
        { notifItems }
      </ul>
    );
  }

  render() {
    return (
      <div className="notifications">
        { this._getNotificationList() }
      </div>
    );
  }
}

export { NotificationComponent };
