export const LSKeys = {
  accountList: 'mp-account-list',
  accountTokens: 'mp-account-tokens',
  accountId: 'mp-account-id',
  currencyRates: 'mp-currency-rates',
  userData: 'mp-user-data',
  userMenu: 'mp-user-menu',
  userSettings: 'mp-user-settings',
  userToken: 'mp-user-token',
};

export const permanentKeys = ['userSettings', 'currencyRates'];
