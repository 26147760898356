import { connect } from 'react-redux';
import { marketPlaceNotyShow, marketPlaceNotyDismiss } from '../../actions';
import { NotificationComponent } from '../../components';

const mapStateToProps = (state, ownProps) => ({
  notifications: state.noty.notifications,
  ...state.noty.options,
  ...ownProps,
});

const mapDispatchToProps = path => dispatch => ({
  onNotyShow: (notifObj) => {
    dispatch(marketPlaceNotyShow(notifObj));
  },
  onNotyDismiss: (notifObj) => {
    dispatch(marketPlaceNotyDismiss(notifObj));
  },
});

export const NotificationContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationComponent);
