import { combineReducers } from 'redux';
import { routerReducer as routing } from 'react-router-redux';
import { notyReducer } from '@mysalegroup/marketplace-noty';

import { accountReducer } from '../reducers';

export default combineReducers({
  routing,
  account: accountReducer,
  noty: notyReducer,
});
