import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { NotificationContainer } from '@mysalegroup/marketplace-noty';

import { marketplaceLoginRestoreToken } from '../actions';
import logo from '../img/logo.svg';
import { LoginRouteContainer } from './loginRoute';
import { LoginFormContainer } from './loginForm';
import { AccountsRouteContainer } from './accountsRoute';
import { AccountsListContainer } from './accountsList';
import { PasswordResetFormContainer } from './passwordResetForm';
import { PasswordRestoreFormContainer } from './passwordRestoreForm';
import { LogoutRouteContainer } from './logoutRoute';
import { JoinUsFormContainer } from './joinUsForm';

class AuthRoute extends Component {
  componentDidMount() {
    const { restoreToken } = this.props;
    restoreToken();
  }

  render() {
    return (
      <div className="login-page-wrapper">
        <NotificationContainer />
        <div>
          <div className="login-page-logo">
            <a href="https://www.mysalemarketplace.com/" target="_self" rel="noopener noreferrer">
              <img height="120px" width="340px" alt="Marketplace" src={logo} />
            </a>
          </div>
          <Switch>
            <LoginRouteContainer exact path="/" component={LoginFormContainer} />
            <AccountsRouteContainer path="/accounts" component={AccountsListContainer} />
            <Route path="/reset-password" component={PasswordResetFormContainer} />
            <Route path="/restore-password" component={PasswordRestoreFormContainer} />
            <Route path="/join-us" component={JoinUsFormContainer} />
            <LogoutRouteContainer path="/logout" />
            <Route path="*" component={LoginFormContainer} />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  restoreToken: () => {
    dispatch(marketplaceLoginRestoreToken());
  },
});

export const AuthRouteContainer = withRouter(
  connect(
    null,
    mapDispatchToProps,
  )(AuthRoute),
);
