import React, { PureComponent, createRef } from 'react';
import { Link } from 'react-router-dom';
import Recaptcha from 'reaptcha';

import { Logger } from '../containers/newrelic_logger';
import { InputComponent } from './form';
import { RECAPTCHA_SITE_KEY } from '../common';


export class LoginForm extends PureComponent {
  shouldUseRecaptcha = RECAPTCHA_SITE_KEY.length > 0;

  isCaptchaReady = false;

  constructor(props) {
    super(props);

    this.state = {
      userName: '',
      password: '',
    };
    this.recaptchaRef = createRef();
  }


  onChange = (value, field) => {
    this.setState({ [field]: value });
  };

  recaptchaCb = (recaptchaToken) => {
    if (recaptchaToken == null) return;

    this.onSubmit(recaptchaToken);

    this.recaptchaRef.current.reset();
  };

  onSubmit = (recaptchaToken) => {
    const { userName, password } = this.state;
    const { onSubmit } = this.props;

    if (!userName.length || !password.length) return;

    onSubmit(userName, password, recaptchaToken);
  };

  onSubmitAlt = () => {
    const { userName, password } = this.state;
    const { onSubmitAlt } = this.props;

    if (!userName.length || !password.length) return;

    onSubmitAlt(userName, password);
  };

  _onSubmit = (e) => {
    e.preventDefault();

    if (this.shouldUseRecaptcha && this.isCaptchaReady) {
      this.executeCaptcha();
      return;
    }

    this.onSubmitAlt();
  };

  executeCaptcha = () => {
    const { userName, password } = this.state;

    if (!userName.length || !password.length) return;

    this.recaptchaRef.current.execute();
  };

  logCaptchaError = () => {
    Logger.newRelicLog(new Error('Recaptcha error'), { source: 'login form' });
  };

  setCaptchaReady = () => {
    this.isCaptchaReady = true;
  };

  render() {
    const { isLoggingIn } = this.props;
    const { userName, password } = this.state;
    const buttonText = isLoggingIn ? 'Signing in...' : 'Sign in';

    return (
      <form className="login-form" onSubmit={this._onSubmit}>
        {this.shouldUseRecaptcha && (
          <Recaptcha
            ref={this.recaptchaRef}
            sitekey={RECAPTCHA_SITE_KEY}
            size="invisible"
            onVerify={this.recaptchaCb}
            onError={this.logCaptchaError}
            onRender={this.setCaptchaReady}
            theme="dark"
          />
        )}
        <div className="login-form_field">
          <label className="login-form_input-label" htmlFor="mp-input-login">Login</label>
          <InputComponent
            name="userName"
            id="mp-input-login"
            type="text"
            value={userName}
            onChange={this.onChange}
            className="login-form_input-text"
            autoFocus
          />
        </div>
        <div className="login-form_field">
          <label className="login-form_input-label" htmlFor="mp-input-password">Password</label>
          <InputComponent
            name="password"
            id="mp-input-password"
            type="password"
            value={password}
            onChange={this.onChange}
            className="login-form_input-text"
          />
        </div>
        <div className="login-form_field">
          <input
            type="submit"
            value={buttonText}
            id="mp-button-submit"
            className="login-form_input-button"
            disabled={isLoggingIn}
          />
        </div>
        <div className="login-form_field login-form_field__center">
          <Link
            className="caption-above"
            to="/restore-password"
            title="Click to to restore password"
          >
            {'Forgot Password?'}
          </Link>
        </div>
        <div className="login-form_field login-form_field__center">
          <Link
            className="caption-above"
            to="/join-us"
          >
            {'Join Us!'}
          </Link>
        </div>
      </form>
    );
  }
}
