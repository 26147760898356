import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import createHistory from 'history/createHashHistory';
import { syncHistoryWithStore } from 'react-router-redux';

import { Root } from './Root';
import configureStore from './infrastructure/configureStore';
import './scss/app.scss';
import './scss/buttons.scss';


const hashHistory = createHistory();
const store = configureStore({}, hashHistory);
const history = syncHistoryWithStore(hashHistory, store);

// Render the React application to the DOM
// Root component is to bootstrap Provider, Router and DevTools
ReactDOM.render(
  <Root history={history} store={store} />,
  document.getElementById('marketplace-root'),
);
