import { call, select } from 'redux-saga/effects';
import { getToken } from '../../common';
import { Logger } from '../../containers';

export function* apiCall({
  payload,
  onSuccess,
  onError,
  onBadResponse,
  apiName = '',
  URL,
  method = 'GET',
}) {
  try {
    const token = yield select(getToken);
    const headers = {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    if (payload) {
      headers.body = JSON.stringify(payload);
    }
    const response = yield call(fetch, URL, headers);
    if (response.ok) {
      if (onSuccess) {
        return yield onSuccess(response);
      }
      return yield (response.json());
    }

    if (onBadResponse) {
      return yield onBadResponse(response);
    }
    console.warn(`${apiName} - Bad response: ${response.status} ${response.statusText}`);
  } catch (e) {
    Logger.newRelicLog(e);
    if (onError) {
      return yield onError(e);
    }
    console.error(apiName, e);
  }
  return null;
}
