import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import { AuthRouteContainer } from './containers';

export const Root = ({ store }) => (
  <Provider store={store}>
    <Router>
      <AuthRouteContainer path="/" />
    </Router>
  </Provider>
);
