import React, { PureComponent, createRef } from 'react';
import { Link } from 'react-router-dom';
import Recaptcha from 'reaptcha';
import Select from 'react-select';

import { Logger } from '../containers/newrelic_logger';
import { RECAPTCHA_SITE_KEY, saleCategoryOptions, optionsStyles, countriesList } from '../common';

export class JoinUsForm extends PureComponent {
  shouldUseRecaptcha = RECAPTCHA_SITE_KEY.length > 0;

  isCaptchaReady = false;

  constructor(props) {
    super(props);

    const sortedCountriesList = countriesList.sort((a, b) => {
      if (a.label > b.label) return 1;
      if (b.label > a.label) return -1;
      return 0;
    });

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      saleCategory: null,
      country: null,
      companyName: '',
      tradingName: '',
      message: '',
      countries: sortedCountriesList
    };
    this.recaptchaRef = createRef();
    this.focusInputRef = createRef();
  }

  componentDidMount() {
    this.focusInputRef.current.focus();
  }

  onChange = (e) => {
    const { value, name } = e.target;

    this.setState({
      [name]: value,
    });
  };

  onChangeSelect = (option, meta) => {
    const { name } = meta;

    this.setState({
      [name]: option,
    });
  };

  recaptchaCb = (recaptchaToken) => {
    if (recaptchaToken == null) return;

    this.onSubmit(recaptchaToken);

    this.recaptchaRef.current.reset();
  };

  onSubmit = (recaptchaToken) => {
    const { onSubmit } = this.props;

    onSubmit(
      this.state,
      recaptchaToken,
    );
  };

  onSubmitAlt = () => {
    const { onSubmitAlt } = this.props;

    onSubmitAlt(this.state);
  };

  _onSubmit = (e) => {
    e.preventDefault();
    const { showWarning } = this.props;
    const { saleCategory, country } = this.state;

    if (!country) {
      showWarning('Country');
      return;
    }

    if (!saleCategory) {
      showWarning('Category');
      return;
    }

    if (this.shouldUseRecaptcha && this.isCaptchaReady) {
      this.executeCaptcha();
      return;
    }

    this.onSubmitAlt();
  };

  executeCaptcha = () => {
    this.recaptchaRef.current.execute();
  };

  logCaptchaError = () => {
    Logger.newRelicLog(new Error('Recaptcha error'), { source: 'login form' });
  };

  setCaptchaReady = () => {
    this.isCaptchaReady = true;
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      phone,
      saleCategory,
      companyName,
      tradingName,
      message,
      countries
    } = this.state;
    const { isJoinUsInProgress } = this.props;
    const buttonText = isJoinUsInProgress ? 'Sending request...' : 'Join Us';

    return (
      <form className="login-form" onSubmit={this._onSubmit}>
        {this.shouldUseRecaptcha && (
          <Recaptcha
            ref={this.recaptchaRef}
            sitekey={RECAPTCHA_SITE_KEY}
            size="invisible"
            onVerify={this.recaptchaCb}
            onError={this.logCaptchaError}
            onRender={this.setCaptchaReady}
            theme="dark"
          />
        )}
        <div className="column-fields-title">
          {'Contact Person'}
        </div>
        <div className="column-fields">

          <div className="column-fields_item">
            <label className="login-form_input-label" htmlFor="firstName">First Name</label>
            <input
              required
              id="firstName"
              name="firstName"
              type="text"
              value={firstName}
              onChange={this.onChange}
              className="login-form_input-text"
              autoComplete="off"
              ref={this.focusInputRef}
            />
          </div>
          <div className="column-fields_item">
            <label className="login-form_input-label" htmlFor="lastName">Second Name</label>
            <input
              required
              id="lastName"
              name="lastName"
              type="text"
              value={lastName}
              onChange={this.onChange}
              className="login-form_input-text"
              autoComplete="off"
            />
          </div>

          <div className="column-fields_item">
            <label className="login-form_input-label" htmlFor="email">E-Mail</label>
            <input
              required
              id="email"
              name="email"
              type="email"
              value={email}
              onChange={this.onChange}
              className="login-form_input-text"
              autoComplete="off"
            />
          </div>
          <div className="column-fields_item">
            <label className="login-form_input-label" htmlFor="phone">Phone</label>
            <input
              id="phone"
              name="phone"
              type="phone"
              value={phone}
              onChange={this.onChange}
              className="login-form_input-text"
              autoComplete="off"
            />
          </div>
        </div>
        <div className="column-fields-title">
          {'Company Data'}
        </div>
        <div className="column-fields">

          <div className="column-fields_item">
            <label className="login-form_input-label" htmlFor="companyName">Company Name</label>
            <input
              required
              id="companyName"
              name="companyName"
              type="text"
              value={companyName}
              onChange={this.onChange}
              className="login-form_input-text"
              autoComplete="off"
            />
          </div>
          <div className="column-fields_item">
            <label className="login-form_input-label" htmlFor="tradingName">Trading Name</label>
            <input
              id="tradingName"
              name="tradingName"
              type="text"
              value={tradingName}
              onChange={this.onChange}
              className="login-form_input-text"
              autoComplete="off"
            />
          </div>
          <div className="column-fields_item">
            <label className="login-form_input-label" htmlFor="country">Country</label>
            <Select
              options={countries}
              onChange={this.onChangeSelect}
              placeholder="Start typing..."
              isClearable
              isSearchable
              id="country"
              name="country"
              styles={optionsStyles}
            />
          </div>
          <div className="column-fields_item">
            <label className="login-form_input-label" htmlFor="saleCategory">Category</label>
            <Select
              options={saleCategoryOptions}
              onChange={this.onChangeSelect}
              value={saleCategory}
              isSearchable={false}
              id="saleCategory"
              name="saleCategory"
              styles={optionsStyles}
            />
          </div>
          <div className="column-fields_item">
            <label className="login-form_input-label" htmlFor="message">Message</label>
            <textarea
              id="message"
              name="message"
              value={message}
              onChange={this.onChange}
              className="login-form_input-text"
              autoComplete="off"
            />
          </div>
        </div>
        <div className="login-form_field">
          <input type="submit" value={buttonText} className="login-form_input-button" disabled={isJoinUsInProgress} />
        </div>
        <div className="login-form_field">
          <Link
            className="account-item-caption caption-above"
            to="/"
          >
            {'Back To Login'}
          </Link>
        </div>
      </form>
    );
  }
}
