import { apiCall } from './api';

export function* userLogin({
  payload,
  onSuccess,
  onBadResponse,
  onError,
  shouldUseRecaptcha,
}) {
  return yield apiCall({
    URL: `/api/login/v1/auth/${shouldUseRecaptcha ? 'login' : 'login-standby'}`,
    apiName: 'userLogin',
    method: 'POST',
    payload,
    onSuccess,
    onBadResponse,
    onError,
  });
}

export function* getUser({
  onSuccess,
  onBadResponse,
  onError,
}) {
  return yield apiCall({
    URL: '/api/login/v1/user',
    apiName: 'getUser',
    method: 'POST',
    onSuccess,
    onBadResponse,
    onError,
  });
}

export function* accountLogin(id) {
  return yield apiCall({
    URL: `/api/login/v1/auth/accounts/${id}/login`,
    apiName: 'accountLogin',
    method: 'POST',
  });
}

export function* resetPassword({
  payload,
  onSuccess,
  onBadResponse,
  onError,
}) {
  return yield apiCall({
    URL: '/api/login/v1/auth/reset-password',
    apiName: 'resetPassword',
    method: 'PUT',
    payload,
    onSuccess,
    onBadResponse,
    onError,
  });
}

export function* restorePassword({
  payload,
  onSuccess,
  onBadResponse,
  onError,
  shouldUseRecaptcha,
}) {
  return yield apiCall({
    URL: `/api/login/v1/auth/${shouldUseRecaptcha ? 'restore-password' : 'restore-password-standby'}`,
    apiName: 'restorePassword',
    method: 'POST',
    payload,
    onSuccess,
    onBadResponse,
    onError,
  });
}

export function* joinUs({
  payload,
  onSuccess,
  onBadResponse,
  onError,
  shouldUseRecaptcha,
}) {
  return yield apiCall({
    URL: `/api/login/v1/${shouldUseRecaptcha ? 'joinus' : 'joinus-standby'}`,
    apiName: 'joinUs',
    method: 'POST',
    payload,
    onSuccess,
    onBadResponse,
    onError,
  });
}
