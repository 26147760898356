import {
  put, takeLatest, call,
} from 'redux-saga/effects';
import { marketPlaceErrorNotyShow, marketPlaceSuccessNotyShow } from '@mysalegroup/marketplace-noty';

import {
  MARKETPLACE_LOGIN_JOIN_US_SUBMIT,
  marketplaceLoginUpdateAccountProperty,
} from '../actions';
import { joinUs } from './api';
import { AccProps } from '../common';

function prepareSubmitModel(viewModel) {
  const {
    firstName,
    lastName,
    email,
    phone,
    saleCategory,
    country,
    companyName,
    tradingName,
    message,
    recaptchaToken,
  } = viewModel;

  return {
    firstName,
    lastName,
    email,
    phone,
    saleCategory: {
      categoryCode: saleCategory.value,
      categoryName: saleCategory.label,
    },
    country: {
      countryCode: country ? country.value : '',
      countryName: country ? country.label : '',
    },
    company: {
      companyName,
      tradingName,
    },
    message,
    recaptchaToken,
  };
}

function* handleJoinUsSubmit(action) {
  yield put(marketplaceLoginUpdateAccountProperty(AccProps.isJoinUsInProgress, true));

  const payload = prepareSubmitModel(action.payload);
  const { shouldUseRecaptcha } = action.payload;

  yield call(joinUs, {
    shouldUseRecaptcha,
    payload,
    * onSuccess() {
      yield put(marketPlaceSuccessNotyShow(
        '<b>Request is submitted. Please, check your email.</b>',
        {
          timeout: 20000,
          closeWith: 'click',
        },
      ));
    },
    * onBadResponse() {
      yield put(marketPlaceErrorNotyShow(
        '<b>Something went wrong. Please, try again later.</b>',
        {
          timeout: 20000,
          closeWith: 'click',
        },
      ));
    },
    * onError() {
      yield put(marketPlaceErrorNotyShow(
        '<b>Something went wrong. Please, try again later.</b>',
        {
          timeout: 20000,
          closeWith: 'click',
        },
      ));
    },
  });

  yield put(marketplaceLoginUpdateAccountProperty(AccProps.isJoinUsInProgress, false));
}

export function* watchJoinUsSubmit() {
  yield takeLatest(MARKETPLACE_LOGIN_JOIN_US_SUBMIT, handleJoinUsSubmit);
}
